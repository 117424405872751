import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.min.css";


const SignInView = lazy(() => import("./components/account/SignInForm"));
const ProductListView = lazy(() => import("./views/product/List"));
const VendorRegisterForm = lazy(() => import("./components/VendorRegistration"));

const NotFoundView = lazy(() => import("./views/pages/404"));
const InternalServerErrorView = lazy(() => import("./views/pages/500"));

const PurchaseView = lazy(()=>import("./views/purchaseOrder/PurchaseView"))

function App() {
  return (
    <BrowserRouter>
      <React.Fragment>
        {/* <Header /> */}
        {/* <TopMenu /> */}
        <Suspense
          fallback={
            <div className="text-white text-center mt-3">Loading...</div>
          }
        >
          <Routes>
          <Route exact path="/" element={<SignInView/>} />
            <Route exact path="/catalog" element={<ProductListView/>} />
            {/* /password/reset/:token */}
            <Route exact path={`/purchase-order/view`} element={<PurchaseView/>}/>
            <Route exact path={`/company/register`} element={<VendorRegisterForm/>}/>

           
            <Route exact path="/500" element={<InternalServerErrorView/>} />
            <Route path="*" element={<NotFoundView/>} />
          </Routes>
        </Suspense>

      </React.Fragment>
    </BrowserRouter>
  );
}

export default App;
