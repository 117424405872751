import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios";

const initialState = {
    isloading: false,
    error: null,
    status: false,
    message: '',

    vendorRegisterState: {
        data: null,
        loading: false,
        error: null,
    }
}



export const registerPendingVendor = createAsyncThunk(
    'vendor/register',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            if (!data) {
                rejectWithValue('Please Provide Data!')
            }
            const reqData = {
                name: data.name,
                email: data.email,
                mobile: data.mobile,
                branches: data.branches,
            };

            dispatch(registerVendorStart());

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/vendor/register`, reqData);
            // const response = await fetch(`${process.env.REACT_APP_API_URL}/api/vendor/register`, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(reqData)
            // });

            if (response?.status) {

                dispatch(registerVendorSuccess(response));

            } else {
                const errorMsg = response?.data?.message ?? 'Something went wrong!!';
                dispatch(registerVendorFailure(errorMsg));
                return rejectWithValue(errorMsg);
            }

        } catch (error) {
            const errorMsg = error?.message ?? 'Something went wrong!!';
            dispatch(registerVendorFailure(errorMsg));
            return rejectWithValue(errorMsg);
        }
    }
)

const vendorSlice = createSlice(
    {
        name: 'vendor',
        initialState,
        reducers: {
            registerVendorStart(state) {
                state.vendorRegisterState.loading = true;
                state.vendorRegisterState.error = null;
            },
            registerVendorSuccess(state, action) {
                state.vendorRegisterState.loading = false;
                state.vendorRegisterState.error = null;
                state.vendorRegisterState.data = action.payload?.data;
            },
            registerVendorFailure(state, action) {
                state.vendorRegisterState.loading = false;
                state.vendorRegisterState.error = action.payload;
            },
        }
    }
)

export const { registerVendorStart, registerVendorSuccess, registerVendorFailure } = vendorSlice.actions;

export default vendorSlice.reducer;