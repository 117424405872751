import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    isLoading: false,
    error: null,
    status: false,
    message: "",

    cityListState: {
        data: [],
        loading: null,
        error: null,
        totalCount: 0,
    },
}


export const fetchCityList = createAsyncThunk(
    'state/fetchCityList',
    async ({ state,country }, { dispatch, rejectWithValue }) => {

        try {
            dispatch(fetchCityListStart());

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/city?page=${1}&size=${100000000000000}&state=${state}&country=${country}`);

            dispatch(fetchCityListSuccess({ data: response.data.data.cityList, totalCount: response.data.totalCount }));

            return response?.data?.status

        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An unknown error occurred';
            dispatch(fetchCityListFailure(errorMessage));
            return rejectWithValue(errorMessage);
        }
    }
);




const citySlice = createSlice({
    name: 'city',
    initialState,
    reducers: {

        fetchCityListStart(state) {
            state.cityListState.loading = true;
            state.cityListState.error = null;
        },
        fetchCityListSuccess(state, action) {
            state.cityListState.loading = false;
            const { data, totalCount } = action.payload ?? {};
            state.cityListState.data = data ?? [];
            state.cityListState.totalCount = totalCount ?? 0;
            state.cityListState.error = null;
        },
        fetchCityListFailure(state, action) {
            state.cityListState.loading = false;
            state.cityListState.error = action.payload;
        },

    }
})

export const { fetchCityListStart, fetchCityListSuccess, fetchCityListFailure } = citySlice.actions;

export default citySlice.reducer;