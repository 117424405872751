import { configureStore } from '@reduxjs/toolkit'
import { reducer as formReducer } from "redux-form";
import productSlice from './slice/productSlice';
import configSlice from './slice/configSlice';
import stateListSlice from "./slice/stateSlice";
import cityListSlice from "./slice/citySlice";
import countryListSlice from "./slice/countrySlice";
import registerVendorSlice from "./slice/vendorSlice";




const store = configureStore({
  reducer: {
    form: formReducer,
    productLists: productSlice,
    configs: configSlice,
    cityList: cityListSlice,
    stateList: stateListSlice,
    countryList: countryListSlice,
    registerVendor: registerVendorSlice,

  },
});

export default store;

