import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    isLoading: false,
    error: null,
    status: false,
    message: "",

    stateListState: {
        data: [],
        loading: null,
        error: null,
        totalCount: 0,
    },
}


export const fetchStateList = createAsyncThunk(
    'state/fetchStateList',
    async ({ country }, { dispatch, rejectWithValue }) => {
        try {
            dispatch(fetchStateListStart());


            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/state?page=${1}&size=${100000000000}&country=${country}`);

            dispatch(fetchStateListSuccess({ data: response.data.data.stateList, totalCount: response.data.totalCount }));

            return response?.data?.status
            
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An unknown error occurred';
            dispatch(fetchStateListFailure(errorMessage));
            return rejectWithValue(errorMessage);
        }
    }
);




const stateSlice = createSlice({
    name: 'state',
    initialState,
    reducers: {

        fetchStateListStart(state) {
            state.stateListState.loading = true;
            state.stateListState.error = null;
        },
        fetchStateListSuccess(state, action) {
            state.stateListState.loading = false;
            const { data, totalCount } = action.payload ?? {};
            state.stateListState.data = data ?? [];
            state.stateListState.totalCount = totalCount ?? 0;
            state.stateListState.error = null;
        },
        fetchStateListFailure(state, action) {
            state.stateListState.loading = false;
            state.stateListState.error = action.payload;
        },

    }
})

export const { fetchStateListStart, fetchStateListSuccess, fetchStateListFailure } = stateSlice.actions;

export default stateSlice.reducer;