import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from 'js-cookie';

const initialState = {
    isLoading: false,
    error: null,
    status:false,
    message:"",
    basicConfig:null
}

export const verifyPassword = createAsyncThunk(
    'config/config',
    async (enterPassword, { dispatch, rejectWithValue }) => {

        try {
            dispatch(getConfigStart());
               console.log('responseefd')
            // const body = {
            //     id: "65d99ed402075e96b75f9400",
            //     catalogPassword: enterPassword
            // };
   
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/catalogPasswordVerify?&catalogPassword=${enterPassword}`);
      
            dispatch(getConfigSuccess(response.data));
          
            return response?.data?.status
         
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An unknown error occurred';
            dispatch(getConfigFailure(errorMessage));
            return rejectWithValue(errorMessage);
        }
    }
);

export const fetchBasicConfig = createAsyncThunk(
    'config/config',
    async (_, { dispatch, rejectWithValue }) => {

        try {
            dispatch(getConfigBasicStart());
       
   
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/catalogConfigBasic`);
      
            dispatch(getConfigBasicSuccess(response.data));
          
            return response?.data?.status
         
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An unknown error occurred';
            dispatch(getConfigBasicFailure(errorMessage));
            return rejectWithValue(errorMessage);
        }
    }
);




const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        getConfigStart(state) {
            state.isLoading = true;
            state.error = null;

        },
        getConfigSuccess(state, action) {
            state.isLoading = false;
            state.status = action.payload.status
            state.message = action.payload.message

        },
        getConfigFailure(state, action) {
            state.error = action.payload;
            state.isLoading = false;
           
        },
        getConfigBasicStart(state) {
            state.isLoading = true;
            state.error = null;

        },
        getConfigBasicSuccess(state, action) {
            state.isLoading = false;
            state.basicConfig = action.payload.data
            state.message = action.payload.message

        },
        getConfigBasicFailure(state, action) {
            state.error = action.payload;
            state.isLoading = false;
           
        },
        
        
        
    }
})

export const { getConfigStart, getConfigSuccess, getConfigFailure,getConfigBasicStart,getConfigBasicSuccess,getConfigBasicFailure
} = configSlice.actions;

export default configSlice.reducer;