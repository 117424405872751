import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    isLoading: false,
    error: null,
    status: false,
    message: "",

    countryListState: {
        data: [],
        loading: null,
        error: null,
        totalCount: 0,

    },
}


export const fetchCountryList = createAsyncThunk(
    'state/fetchCountryList',
    async (_, { dispatch, rejectWithValue }) => {
        console.log("country called")
        try {
            dispatch(fetchCountryListStart());

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/country`);
console.log(response,'response_1')
            dispatch(fetchCountryListSuccess({ data: response.data.data.countryList, totalCount: response.data.totalCount }));

            return response?.data?.status

        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An unknown error occurred';
            dispatch(fetchCountryListFailure(errorMessage));
            return rejectWithValue(errorMessage);
        }
    }
);




const countrySlice = createSlice({
    name: 'country',
    initialState,
    reducers: {

        fetchCountryListStart(state) {
            state.countryListState.loading = true;
            state.countryListState.error = null;
        },
        fetchCountryListSuccess(state, action) {
            state.countryListState.loading = false;
            const { data, totalCount } = action.payload ?? {};
            state.countryListState.data = data ?? [];
            state.countryListState.totalCount = totalCount ?? 0;
            state.countryListState.error = null;
        },
        fetchCountryListFailure(state, action) {
            state.countryListState.loading = false;
            state.countryListState.error = action.payload;
        },

    }
})

export const { fetchCountryListStart, fetchCountryListSuccess, fetchCountryListFailure } = countrySlice.actions;

export default countrySlice.reducer;