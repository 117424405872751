import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    isLoading: false,
    error: null,
    productList: [],
    totalPage: null,
    currentPage: null,
    totalCount: null,
    category: [],
    brandList:[],
    singlePurchaseOrderState: {
        data: null,
        loading: null,
        error: null,
    },
    singleConfigState: {
        data: null,
        loading: null,
        error: null,
    }
    
   
}

export const getProduct = createAsyncThunk(
    'product/Product',
    async ({ page, pageSize, catalogue, name, category, selllingPrice, priceOrNameOrder,brand }, { dispatch, rejectWithValue }) => {


        try {
            dispatch(getProductStart());
            console.log("productNAme", name)


            let searchQuery = '';
            if ((priceOrNameOrder != null) && (priceOrNameOrder != "") ) {
                searchQuery += `&priceOrNameOrder=${priceOrNameOrder}`;
            }
            if (catalogue != null) {
                searchQuery += `&catalogue=${catalogue}`;
            }
            if ((name != null) && (name != "")) {
                searchQuery += `&name=${name}`;
            }
            
          
            if ((category != null) && (category.length > 0)) {
                searchQuery += `&category=${category}`;
            }


            if (selllingPrice != null && selllingPrice !== undefined && (selllingPrice.length > 0)) {
                searchQuery += `&selllingPrice=${selllingPrice}`;
            }
            if((brand != null) && (brand.length >0) ){
                searchQuery += `&brand=${brand}`;
            }




            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/product?page=${page}&size=${pageSize}${searchQuery}`)

                 console.log("response321000", response.data?.data)
                console.log("productlist_checking_3",response?.data?.data?.productList)
                dispatch(getProductSuccess(response.data))
           

            // return response?.data?.productList;
        } catch (error) {
            dispatch(getProductFailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)
        }
    }

)



export const fetchPurchaseOrderSingle = createAsyncThunk(
    'purchaseOrder/fetchPurchaseOrderSingle',
    async ({ entitiyId ,convert }, { dispatch, rejectWithValue, getState }) => {
        try {

            dispatch(fetchPurchaseOrderSingleStart());
            console.log("debug_1")

            // const response = await fetchApi(`purchase-order/${entitiyId}`, { method: 'GET' });
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/purchase-order/${entitiyId}`)

            console.log("debug_2" , response.data)

            if (response?.status) {

                const data = response.data.data;

                data.branchId = data?.branch._id;
                data.vendorId = data?.vendor._id;
                data.vendorBranchId = data?.vendorBranch._id;
                //data.tdsId = data?.tds;
                if (data.tcsId && data.tcsAmount) {
                    data.tcsDone = true;
                }

                const productList = data?.productList;

                if (productList?.length) {
                    for (const productItem of productList) {
                        productItem.name = productItem.productId.name;
                        productItem.code = productItem.productId.productCode;
                        productItem.hsnCode = productItem.productId.hsnCode;
                        productItem.productId = productItem.productId._id;
                    }
                }

                if (convert) {
                    data.converted = true;
                    data.type = 'Invoice';
                    data.orginalPo = entitiyId;
                }

                dispatch(fetchPurchaseOrderSingleSuccess(data));

            } else {
                const errorMsg = response?.data?.message ?? 'Something went wrong!!';
                dispatch(fetchPurchaseOrderSingleFailure(errorMsg));
                return rejectWithValue(errorMsg);
            }


        } catch (error) {
            const errorMsg = error?.message ?? 'Something went wrong!!';
            dispatch(fetchPurchaseOrderSingleFailure(errorMsg));
            return rejectWithValue(errorMsg);
        }
    }
);

export const getProductCategory = createAsyncThunk(
    'category/category',
    async ({ page, pageSize, catalogue, name }, { dispatch, rejectWithValue }) => {
        console.log("pageSize321", pageSize)
        try {
            dispatch(getProductCategoryStart());

            let searchQuery = '';
            if (catalogue != null) {
                searchQuery += `&catalogue=${catalogue}`;
            }
            if (name != null) {
                searchQuery += `&name=${name}`;
            }


            // const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/category`)

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/category?page=${page}&size=${pageSize}${searchQuery}`)

            dispatch(getProductCategorySuccess(response.data))

            console.log("response321asa", response.data?.data)
            // return response?.data?.productList;
        } catch (error) {
            dispatch(getProductCategoryFailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)
        }
    }
)




export const fetchConfig = createAsyncThunk(
    'config/fetchConfig',
    async (_, { dispatch, rejectWithValue, getState }) => {
        try {

            dispatch(fetchConfigStart());

            // const response = await fetchApi(`config/65d99ed402075e96b75f9400`, { method: 'GET' });

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/config/65d99ed402075e96b75f9400`)


            if (response?.status) {

                dispatch(fetchConfigSuccess(response.data.data));
                return response.data;
            } else {
                const errorMsg = response?.data?.message ?? 'Something went wrong!!';
                dispatch(fetchConfigFailure(errorMsg));
                return rejectWithValue(errorMsg);
            }


        } catch (error) {
            const errorMsg = error?.message ?? 'Something went wrong!!';
            dispatch(fetchConfigFailure(errorMsg));
            return rejectWithValue(errorMsg);
        }
    }
);
export const getBrandList = createAsyncThunk(
    'category/category',
    async ({page,pageSize,name}, { dispatch, rejectWithValue }) => {
         console.log("pagination",page,pageSize)
        try {
             dispatch(getProductBrandStart());

             let searchQuery = '';
             if(name != null){
                searchQuery += `&name=${name}`;
            }

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/brand?page=${page}&size=${pageSize}${searchQuery}`)
              
            dispatch(getProductBrandSuccess(response.data))
          
            // return response?.data?.productList;
        } catch (error) {
            dispatch(getProductBrandFailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)
        }
    }
)

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        getProductStart(state) {
            state.isLoading = true;
            state.error = null;

        },
        getProductSuccess(state, action) {
            state.isLoading = false;
            state.productList = action.payload.data?.productList;
            state.totalPage = action.payload.data?.totalPages;
            state.totalCount = action.payload.data?.totalCount;
            state.currentPage = action.payload.data?.currentPage;

        },
        getProductFailure(state, action) {
            state.error = action.payload;
            state.isLoading = false;
            state.singlealbum = null
        },
        getProductCategoryStart(state) {
            state.isLoading = true;
            state.error = null;

        },
        getProductCategorySuccess(state, action) {
            state.isLoading = false;
            state.category = action.payload.data?.categoryList;
            state.totalPage = action.payload.data?.totalPages;
            state.totalCount = action.payload.data?.totalCount;
            state.currentPage = action.payload.data?.currentPage;
        },
        getProductCategoryFailure(state, action) {
            state.error = action.payload;
            state.isLoading = false;
            state.singlealbum = null
        },
        fetchPurchaseOrderSingleStart(state) {
            state.singlePurchaseOrderState.loading = true;
            state.singlePurchaseOrderState.error = null;
        },
        fetchPurchaseOrderSingleSuccess(state, action) {
            state.singlePurchaseOrderState.loading = false;
            state.singlePurchaseOrderState.data = action.payload;
            state.singlePurchaseOrderState.error = null;
        },
        fetchPurchaseOrderSingleFailure(state, action) {
            state.singlePurchaseOrderState.loading = false;
            state.singlePurchaseOrderState.error = action.payload;
        },
        fetchConfigStart(state) {
            state.singleConfigState.loading = true;
            state.singleConfigState.error = null;
        },
        fetchConfigSuccess(state, action) {
            state.singleConfigState.loading = false;
            state.singleConfigState.data = action.payload;
            state.singleConfigState.error = null;
        },
        fetchConfigFailure(state, action) {
            state.singleConfigState.loading = false;
            state.singleConfigState.error = action.payload;
        },
        getProductBrandStart(state) {
            state.isLoading = true;
            state.error = null;

        },
        getProductBrandSuccess(state, action) {
            state.isLoading = false;
            state.brandList = action.payload.data?.brandList;
        },
        getProductBrandFailure(state, action) {
            state.error = action.payload;
            state.isLoading = false;
            state.singlealbum = null
        },

    }
})

export const {fetchConfigStart , fetchConfigSuccess,fetchConfigFailure, fetchPurchaseOrderSingleStart,fetchPurchaseOrderSingleSuccess,fetchPurchaseOrderSingleFailure, getProductStart, getProductSuccess, getProductFailure, getProductCategoryStart, getProductCategorySuccess, getProductCategoryFailure,getProductBrandStart,getProductBrandSuccess,getProductBrandFailure

} = productSlice.actions;

export default productSlice.reducer;